<script lang="ts" setup>
import { ref } from "vue";

definePageMeta({
  layout: "homepage",
});

const tocLinks = [
  { id: "start-with-ai" },
  { id: "ai-screening" },
  { id: "contact-form" },
  { id: "consulting" },
  { id: "services" },
  { id: "workflow" },
  { id: "team" },
];

const contentContainer = ref<null | HTMLElement>(null);
const { activeTableOfContentsItemId } = useTableOfContent(contentContainer);
</script>

<template>
  <div class="toc-container" style="z-index: 1">
    <TableOfContent
      :activeTocId="activeTableOfContentsItemId"
      :tocLinks="tocLinks"
      page="saxonyaiHome"
    />
  </div>
  <div
    ref="contentContainer"
    class="saxony-ai__index"
    style="max-width: 100%; overflow-x: hidden"
  >
    <HomepageHero id="start-with-ai" class="toc-item">
      <template #header>
        Starten Sie mit künstlicher Intelligenz durch!
      </template>

      <HomepageHeroItem color="blue" flip orbit="7" rotate="-12" rotate-md="45">
        <NuxtLink to="#datenerfassung-und-integration">
          Datenerfassung<br />und -integration
        </NuxtLink>
      </HomepageHeroItem>

      <HomepageHeroItem color="orange" orbit="6" rotate="15" rotate-md="-45">
        <NuxtLink to="#hoch-spezialisierte-ki-lösung">
          Hochspezialisierte<br />KI-Lösungen
        </NuxtLink>
      </HomepageHeroItem>

      <HomepageHeroItem
        color="dark-blue"
        flip
        orbit="5"
        rotate="-15"
        rotate-md="70"
      >
        <NuxtLink to="#prozessmodellierung-und-entscheidungsunterstützung">
          Prozessmodellierung &<br />Entscheidungsunterstützung
        </NuxtLink>
      </HomepageHeroItem>

      <HomepageHeroItem color="green" orbit="4" rotate="20" rotate-md="-85">
        <NuxtLink to="#datenaufbereitung-und-analyse"
          >Datenaufbereitung und<br />
          -analyse
        </NuxtLink>
      </HomepageHeroItem>

      <HomepageHeroItem color="red" flip orbit="3" rotate="-25" rotate-md="30">
        <NuxtLink to="#strategische-ki-beratung">
          Strategische KI-Beratung<br />& Bedarfsanalyse
        </NuxtLink>
      </HomepageHeroItem>
      <HomepageHeroItem
        color="yellow"
        orbit="2"
        rotate="27"
        rotate-md="-60"
        style="--offset-x: 8px"
      >
        <NuxtLink to="#individualisierung-von-open-source-modellen">
          Individualisierung von<br />Open-Source-Modellen
        </NuxtLink>
      </HomepageHeroItem>

      <HomepageHeroItem
        color="purple"
        flip
        orbit="1"
        rotate="-40"
        rotate-md="10"
        style="--offset-x: 12px"
      >
        <NuxtLink to="#ki-schulungen-und-workshops">
          KI-Schulungen<br />& Workshops
        </NuxtLink>
      </HomepageHeroItem>
    </HomepageHero>

    <v-container id="ai-screening" class="toc-item mt-md-n20 position-relative">
      <h2 class="text-h2 mt-14 mt-lg-0 justify-center align-center">
        <span class="bg-green rounded py-1 px-2">kostenloses</span
        >&nbsp;KI-Screening
      </h2>

      <div class="my-6 my-md-12 text-center">
        Mit unserem kostenlosen Beratungsangebot möchten wir Ihnen den Weg in
        die Ära der KI erleichtern, indem wir:
      </div>

      <div
        style="
          display: grid;
          gap: 4.5rem;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        "
      >
        <v-card class="pa-4" color="#e8ddf5">
          <template #text>
            <v-row>
              <v-col cols="auto">
                <v-avatar color="#2e2759" size="x-large">
                  <HwImage src="/images/Brain.svg" />
                </v-avatar>
              </v-col>

              <v-col>
                Die vielfältigen Möglichkeiten von KI für die Optimierung von
                Geschäftsprozessen aufzeigen
              </v-col>
            </v-row>
          </template>
        </v-card>

        <v-card class="pa-4" color="#cbe9f5">
          <template #text>
            <v-row>
              <v-col cols="auto">
                <v-avatar color="#2e2759" size="x-large">
                  <HwImage src="/images/Chart.svg" />
                </v-avatar>
              </v-col>

              <v-col>
                Bislang ungenutzte Potenziale für Digitalisierung in Ihrem
                Unternehmen identifizieren und deren Kosten-Nutzen-Verhältnis
                analysieren
              </v-col>
            </v-row>
          </template>
        </v-card>

        <v-card class="pa-4" color="#cbe9f5">
          <template #text>
            <v-row>
              <v-col cols="auto">
                <v-avatar color="#2e2759" size="x-large">
                  <HwImage src="/images/Speech.svg" />
                </v-avatar>
              </v-col>

              <v-col>
                Ihre individuellen Fragen und Bedenken ernst nehmen und mit
                fundierten, verständlichen Antworten zur Seite stehen.
              </v-col>
            </v-row>
          </template>
        </v-card>
      </div>

      <v-card class="my-6 my-lg-16 pa-4 px-sm-8 py-sm-8 px-md-16">
        <h2 class="text-h2 d-flex mx-auto mt-6 mt-lg-16">
          Ablauf der kostenlosen Beratung
        </h2>

        <v-timeline
          align="start"
          class="gr-16"
          density="compact"
          line-inset="16"
          side="end"
        >
          <v-timeline-item dot-color="purple" size="small">
            <h3 class="text-h3 d-flex">Erstgespräch (bis 2h)</h3>

            <ul style="margin-inline-start: 1rem">
              <li>
                Kennenlernen: Was macht Ihr Unternehmen; wo sehen Sie
                Herausforderungen?
              </li>
              <li>Haben Sie schon Ideen, wo KI helfen könnte?</li>
              <li>
                Festlegung der Art der Beratung (Online oder Vor-Ort in Ihrem
                Unternehmen)
              </li>
            </ul>
          </v-timeline-item>

          <v-timeline-item dot-color="orange" size="small">
            <h3 class="text-h3 d-flex">Beratungsgespräch (bis 4h)</h3>
            <ul style="margin-inline-start: 1rem">
              <li>
                Leitfaden-gestütztes Gespräch zur systematischen Identifikation
                von KI Anwendungsfällen
              </li>
              <li>
                Es können gern Mitarbeiter aus Kernbereichen Ihres Unternehmens
                teilnehmen
              </li>
              <li>
                Unsere KI-Experten stellen passende Technologien in
                verständlicher Sprache vor und erläutern anhand von Beispielen
                Potentiale und Grenzen von KI
              </li>
              <li>
                Unsere Experten befragen Ihre Mitarbeiter, um Prozesse zu
                verstehen und Optimierungspotentiale aufzudecken
              </li>
              <li>Abschließende Fragerunde</li>
            </ul>
          </v-timeline-item>

          <v-timeline-item dot-color="green" size="small">
            <h3 class="text-h3 d-flex">Auswertung</h3>
            <ul style="margin-inline-start: 1rem">
              <li>
                Wir erstellen eine Übersicht zu KI Anwendungsfällen und bewerten
                diese hinsichtlich Kosten, Nutzen und Risiko für Ihr Unternehmen
              </li>
              <li>
                Sie können entscheiden, welche Anwendungsfälle Sie umsetzen oder
                mit einer weiterführenden Beratung vertiefen möchten.
              </li>
            </ul>
          </v-timeline-item>
        </v-timeline>
      </v-card>

      <div class="my-6 my-md-12">
        Wir sind überzeugt, dass sächsische KMU deutlich vom Einsatz von
        KI-Technologien profitieren können. Es ist an der Zeit, Synergieeffekte
        zu nutzen und die Weichen für eine Zukunft zu stellen, in der Ihr
        Unternehmen nicht nur wettbewerbsfähig bleibt, sondern gar in Zeiten des
        Fachkräftemangels prosperiert. Ergreifen Sie jetzt die Initiative und
        nutzen Sie die Möglichkeiten, die KI bietet, um Ihr Unternehmen
        zukunftsfähig zu machen. Beginnen wir gemeinsam diese Reise in eine
        erfolgreichere Zukunft. Ihre Entscheidung heute legt den Grundstein für
        den Erfolg von morgen – und wir stehen bereit, Sie auf jedem Schritt
        dieses Weges zu begleiten.
      </div>

      <!-- TODO: wenn noch zeit ist, kontakt form anpassen wie im design -->

      <ContactForm id="contact-form" class="toc-item" />
    </v-container>

    <v-card
      class="mt-16 mx-auto pa-4 pb-sm-8 pb-md-12 oversize-image-container"
      elevation="0"
      image="/images/background/Gradient_full_1.svg"
    >
      <v-container id="consulting" class="toc-item">
        <h2 class="text-h2 mt-6 mt-lg-16 justify-center align-center">
          <span class="bg-light-blue rounded py-1 px-2">vertiefende</span
          >&nbsp;KI Beratung
        </h2>

        <v-card
          class="my-6 my-lg-16 pa-4 px-sm-8 py-sm-8 px-md-16"
          color="#fff0"
          elevation="0"
        >
          Die folgenden Module decken relevante Aufgaben bei der Entwicklung und
          Umsetzung von Digital- bzw. KI-Projekten bei KMU ab. Hierbei werden
          insbesondere alle Aktivitäten entlang des Datenlebenszyklus
          abgebildet, d.h. von der Datenerhebung, über die Datenverarbeitung und
          -speicherung, und -analyse bis zur Datenvisualisierung und -löschung.
        </v-card>

        <div
          style="
            display: grid;
            gap: 4.5rem;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
          "
        >
          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#30a9d9" size="x-large">
                <HwImage src="/images/Goal.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Zieldefinition der KI-Strategie</h3>
            </template>

            <template #text>
              Definition klarer Ziele für den Einsatz von KI.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#82d9d0" size="x-large">
                <HwImage src="/images/Sources.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Datenerhebung</h3>
            </template>

            <template #text>
              Beratung zu Workflows zur Akquise von Daten aus verschiedenen
              Quellen.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#c4e929" size="x-large">
                <HwImage src="/images/Filter.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Datenaufbereitung</h3>
            </template>

            <template #text>
              Unterstützung beim Design und Implementierung von Methoden zur
              Verbesserung der Datenqualität, Identifikation von Ausreißern,
              Lücken, fehlerhaften Daten, etc.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#82d9d0" size="x-large">
                <HwImage src="/images/Storage.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Datenverwaltung</h3>
            </template>

            <template #text>
              Beratung und Design zu Technologien die effizientes Speichern und
              Abrufen der Daten (z.B. sql/nosql Datenbanken) ermöglichen.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#c4e929" size="x-large">
                <HwImage src="/images/Analysis.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Datenaufbereitung</h3>
            </template>

            <template #text>
              Unterstützung bei der Entwicklung und dem Training von
              KI-Modellen, wie z.B. maschinellem Lernen, Deep Learning oder
              statistischer Datenanalyse und Anwendung von Datenanalysemethoden
              zur Gewinnung von Einsichten und zur Unterstützung der
              Entscheidungsfindung.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#fa8f3c" size="x-large">
                <HwImage src="/images/Arcs.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Datenvisualisierung</h3>
            </template>

            <template #text>
              Beratung zur zielgruppen-gerechten Darstellung der Daten- bzw.
              Ergebnisse aus der Datenanalyse.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#c4e929" size="x-large">
                <HwImage src="/images/Design.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">UX-Design</h3>
            </template>

            <template #text>
              Analyse und das Verständnis der Bedürfnisse der Zielgruppe, um
              nutzerzentrierte Designs zu entwickeln (User Personas, Wireframes
              und Prototypen, Usability-Tests etc.). Das Ziel ist es, durch
              iterative Designverbesserungen die Nutzerzufriedenheit und
              Konversionsraten zu steigern.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#fa8f3c" size="x-large">
                <HwImage src="/images/Test.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Validierung und Testing</h3>
            </template>

            <template #text>
              Durchführung von Tests und Validierung der KI-Modelle, um deren
              Genauigkeit und Zuverlässigkeit sicherzustellen und Beratung zu
              Best Practices für das Testing und die Evaluation von KI-Systemen.
            </template>
          </v-card>

          <v-card class="pa-4 px-sm-8">
            <template #prepend>
              <v-avatar color="#f26d85" size="x-large">
                <HwImage src="/images/Education.svg" />
              </v-avatar>
            </template>

            <template #title>
              <h3 class="text-h3 d-flex">Schulung und Kapazitätsaufbau</h3>
            </template>

            <template #text>
              Beantwortung von generellen Fragen zum Einsatz von KI,
              Bereitstellung von Schulungen und Workshops, um das Verständnis
              und die Fähigkeiten im Umgang mit KI zu fördern.
            </template>
          </v-card>
        </div>
      </v-container>
    </v-card>

    <v-container id="services" class="toc-item">
      <h2 class="text-h2 mt-6 mt-lg-16 justify-center align-center">
        <span class="bg-purple rounded py-1 px-2">Alle Leistungen</span>&nbsp;im
        Überblick
      </h2>

      <v-card
        class="my-6 my-lg-16 pa-4 px-sm-8 py-sm-8 px-md-16"
        color="#fff0"
        elevation="0"
      >
        Wir beraten zu branchenspezifischen Einsatzmöglichkeiten von KI,
        analysieren Ihre Prozesse, identifizieren gemeinsam vielversprechende
        Anwendungsgebiete und entwickeln nicht nur technologisch erstklassige,
        sondern auch wirtschaftlich attraktive Lösungen. Unser Portfolio umfasst
        die Adaption von etablierten Open-Source-Modellen für vertrauliche
        Daten, die Integration bewährter Technologien wie digitale Zwillinge
        oder Chatbots sowie die Entwicklung von maßgeschneiderten KI-Lösungen,
        die auf Ihre spezifischen Herausforderungen zugeschnitten sind.
      </v-card>

      <CardMedia color="#30a9d9" image="/images/consulting.png">
        <template #title>
          <h3 class="text-h3 d-flex">Strategische KI-Beratung</h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>Entwicklung einer Daten- und KI-Strategie</li>
          <li>Identifikation von KI-Anwendungsfällen im Unternehmen</li>
          <li>Wirtschaftliche Optimierung durch gezielten KI-Einsatz</li>
          <li>
            Aufbau von Governance Strukturen zur Qualitätssicherung und
            Minimierung von Risiken
          </li>
        </ul>
      </CardMedia>

      <CardMedia color="#82d9d0" image="/images/process_modelling.png">
        <template #title>
          <h3 class="text-h3 d-flex">
            Prozessmodellierung und Entscheidungsunterstützung
          </h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>
            Design und Entwicklung von digitalen Repräsentationen (Digitaler
            Zwilling) ihrer Assets basierend auf mathematischen Modellen sowie
            KI
          </li>
          <li>
            Entwicklung von Optimierungsroutinen zum bestmöglichen
            Prototyping/Entwicklung bzw. Einsatz ihrer Assets (z.B.
            Rohstoffmanagement, Maschinenentwürfe, Produktionsoptimierung)
          </li>
          <li>Echtzeit-Überwachung und Steuerung Ihrer Anlagen</li>
        </ul>
      </CardMedia>

      <CardMedia color="#c4e929" image="/images/solutions.png">
        <template #title>
          <h3 class="text-h3 d-flex">Hoch-spezialisierte KI-Lösung</h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>
            Maßgeschneiderte Entwicklung und Training von neuronalen Netzen, die
            Ihre individuellen Anwendungsfälle unter Ausnutzung aller
            vorhandenen Daten optimal lösen
          </li>
          <li>
            Einbeziehung von Spezialanforderungen, z.B. Energieeffizienz,
            Echtzeitfähigkeit, Resilienz
          </li>
        </ul>
      </CardMedia>

      <CardMedia color="#1480c4" image="/images/open_source.png">
        <template #title>
          <h3 class="text-h3 d-flex">
            Individualisierung von Open-Source Modellen
          </h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>
            Auswahl von geeigneten Open-Source Modellen, z.B. zum Forecasting,
            Text- & Bildverarbeitung/Computer Vision, Empfehlungssysteme,
            Predictive Maintenance & Prescriptive Analytics
          </li>
          <li>
            Anpassung von Open-Source KI-Modellen an Ihre Geschäftsanforderungen
          </li>
          <li>
            Sicherer Umgang mit sensiblen Daten durch datenschutzkonforme
            Lösungen
          </li>
        </ul>
      </CardMedia>

      <CardMedia color="#fa8f3c" image="/images/data-processing-logo.svg">
        <template #title>
          <h3 class="text-h3 d-flex">Datenerfassung und Integration</h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>Nahtlose Einbindung der KI Anwendungen in Ihre Infrastruktur</li>
          <li>
            Kompatibilitäts- und Leistungsprüfung für die Integration von
            Drittanbieter-Lösungen
          </li>
          <li>
            Anpassung der Modelle zum optimalen Einsatz ihrer Compute Ressourcen
            (z.B. Hochleistungsrechner, Edge Devices)
          </li>
        </ul>
      </CardMedia>

      <CardMedia color="#f26d85" image="/images/data_collection.png">
        <template #title>
          <h3 class="text-h3 d-flex">Datenaufbereitung und Analyse</h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>
            Unterstützung bei der Realisierung eines standardisierten und
            zukunftssicheren Ansatzes zur Bereinigung und Strukturierung der für
            KI-Modelle benötigten Daten
          </li>
          <li>
            Erkenntnisgewinnung durch detaillierte Datenanalyse, Visualisierung
            und Berichterstattung
          </li>
        </ul>
      </CardMedia>

      <CardMedia color="#a376d9" image="/images/workshop.png">
        <template #title>
          <h3 class="text-h3 d-flex">KI-Schulungen und Workshops</h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>
            Fachspezifische Schulungen für Ihr Team, um den Umgang mit
            KI-Technologien zu vermitteln
          </li>
          <li>
            Workshops zur Ideenfindung und Umsetzungsplanung für KI-Projekte
          </li>
        </ul>
      </CardMedia>

      <CardMedia color="#30a9d9" image="/images/support.svg">
        <template #title>
          <h3 class="text-h3 d-flex">Langfristige KI-Betreuung und Support</h3>
        </template>

        <ul style="margin-inline-start: 1rem">
          <li>
            Kontinuierliche Überwachung und Optimierung der KI-Systeme nach der
            Implementierung
          </li>
          <li>
            Reaktionsbereiter Support für aufkommende Fragen und
            Herausforderungen
          </li>
        </ul>
      </CardMedia>

      <h2 class="text-h2 mt-6 mt-lg-16 justify-center align-center">
        <span class="mx-auto my-4 my-lg-8">Technologien</span>
      </h2>

      <IconsAi background="/images/background/Gradient_full_1.svg" />
    </v-container>

    <v-card
      class="mt-16 mx-auto"
      elevation="0"
      image="/images/background/Gradient_full_1.svg"
    >
      <v-container>
        <h2 class="text-h2 mt-6 mt-lg-16 justify-center align-center">
          <span class="mx-auto my-4 my-lg-8">Unsere Arbeitsweise</span>
        </h2>

        <div class="text-center">
          Wir verfolgen bei allen KI-Projekten einen systematischen Ansatz. Wenn
          Sie sich also für uns entscheiden, können Sie Folgendes erwarten:
        </div>

        <v-card class="my-6 my-lg-16 pa-4 px-sm-8 py-sm-8 px-md-16">
          <v-timeline
            align="start"
            class="gr-16"
            density="compact"
            line-inset="16"
            side="end"
          >
            <v-timeline-item dot-color="purple">
              ### Entdeckungsphase: Wir beginnen damit, das Problem zu
              verstehen, das Sie lösen wollen, und arbeiten eng zusammen, um
              Erkenntnisse zu sammeln. Dann definieren wir dann die allgemeine
              Richtung und den Ansatz für die Lösung und nehmen einige grobe
              Schätzungen vor, damit Sie eine klare Vorstellung von dem
              Projektumfang und die zu erwartenden Ergebnisse zu geben.
            </v-timeline-item>

            <v-timeline-item dot-color="dark-blue">
              <h3 class="text-h3 d-flex">Ersteinrichtung:</h3>
              Sobald wir Ihre Bedürfnisse kennen, lernen wir Ihr Team besser
              kennen, um sicherzustellen, dass wir alle Daten haben, die für ein
              erfolgreiches Projekt benötigt werden, und verstehen, wie sie
              gesammelt werden. Anhand des verfügbaren Datensatzes bestimmen
              wir, ob KI die beste Option zur Lösung des des Problems ist.
            </v-timeline-item>

            <v-timeline-item dot-color="blue">
              <h3 class="text-h3 d-flex">MVP bauen:</h3>
              Wir fangen klein an und nutzen den verfügbaren Datensatz, um die
              Tragfähigkeit unserer Idee zu testen. Mit diesem schrittweisen
              Ansatz sind wir können wir feststellen, ob die von uns
              vorgeschlagene Idee effektiv ist und sich die Investition lohnt.
            </v-timeline-item>

            <v-timeline-item dot-color="orange">
              <h3 class="text-h3 d-flex">Skalieren und Integrieren:</h3>
              Sobald wir einen erprobten und getesteten Prototyp haben, arbeiten
              wir daran, die KI-Lösung in Ihr bestehendes System zu integrieren
              und sicherzustellen und stellen sicher, dass sie nahtlos mit Ihren
              Daten zusammenarbeitet, indem wir die Modelle feinabstimmen und
              bei Bedarf Anpassungen vornehmen.
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-container>
    </v-card>

    <v-sheet
      class="mt-6 mx-6 pa-6 pt-0 mx-auto"
      color="rgba(0,0,0,0)"
      max-width="1300px"
    >
      <h2 class="text-h2 mt-6 mt-lg-16 justify-center align-center">
        <span class="mx-auto mb-6 mb-lg-6 mt-14">Unsere Kompetenzen</span>
      </h2>

      <v-row class="gr-6 gr-md-0" justify="space-around" align="stretch">
        <v-col class="pe-lg-8 .pe-xl-10" cols="12" md="6">
          <v-sheet
            class="pa-4 px-sm-8 py-sm-8 px-md-12 px-lg-16 w-100 h-100"
            color="purple"
          >
            <h3 class="text-h3 d-flex">
              Identifizierung von KI-Anwendungsfällen
            </h3>
            Wir unterstützen Sie beim Erkennen, Beurteilen, Priorisieren und
            Definieren potenzieller Anwendungsbereiche für Data Science,
            maschinelles Lernen und künstliche Intelligenz.
          </v-sheet>
        </v-col>

        <v-col class="px-lg-8 px-xl-10" cols="12" md="6">
          <v-sheet
            class="pa-4 px-sm-8 py-sm-8 px-md-12 px-lg-16 w-100 h-100"
            color="blue"
          >
            <h3 class="text-h3 d-flex">Entwurf der Datenstruktur</h3>
            Wir unterstützen Sie bei der Konzeption, Evaluierung und dem
            erfolgreichen Aufbau geeigneter Datenarchitekturen, die Ihnen einen
            effizienten Umgang mit Ihren Daten ermöglichen. die Ihnen einen
            effizienten Umgang mit Ihren Daten ermöglichen.
          </v-sheet>
        </v-col>

        <v-col class="ps-lg-8 ps-xl-10" cols="12" md="6">
          <v-sheet
            class="pa-4 px-sm-8 py-sm-8 px-md-12 px-lg-16 w-100 h-100"
            color="green"
          >
            <h3 class="text-h3 d-flex">
              Auswahl der Werkzeuge und Technologien
            </h3>
            Wir unterstützen Sie bei der Auswahl und Einführung von
            Spitzentechnologien und -werkzeugen in den Bereichen Data Science
            und Künstliche Intelligenz, damit Sie diese effektiv in Ihren
            Projekten einsetzen können.
          </v-sheet>
        </v-col>
      </v-row>

      <h2 class="text-h2 mt-6 mt-lg-16 justify-center align-center">
        <span class="mx-auto mb-6 mt-14 toc-item" id="team">Unser Team</span>
      </h2>
      <div class="mb-6 mb-md-12">
        Unsere Experten für künstliche Intelligenz arbeiten stets eng und
        projektübergreifend mit Kollegen und Kolleginnen, unseren Kunden und
        deren technischen Ansprechpartnern zusammen. Dabei setzen wir in einem
        agilen Entwicklungsprozess meist auf die Kommunikation mit einer fest
        für Sie zur Verfügung stehenden Ansprechperson.
      </div>

      <TeamCardMoreAbout
        slug="artificial-intelligence"
        employee="nico-hoffmann"
        image="images/teams/ki.jpg"
        class="ga-16 ga-lg-32 mt-22"
      />
    </v-sheet>
  </div>
</template>
